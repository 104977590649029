import dayjs from "dayjs";
import baseURL from "../Assets/common/baseURL";
import AboutHeader from "../Components/AboutHeader";

import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Modal,
  Radio,
  Stack,
  Alert,
  Button,
  Snackbar,
  FormLabel,
  TextField,
  Container,
  RadioGroup,
  FormControl,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import API from "../utils/API";
import moment from "moment";

function Career() {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState(false);

  const [file, setFile] = useState(null);
  const [value, setValue] = useState(dayjs());

  const fileTypes = ["JPG", "PDF"];

  const upload = new FormData();

  const handleChange = (file) => setFile(file);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    data.append("first_name", data.get("full-name").split(" ")[0]);
    data.append("last_name", data.get("full-name").split(" ")[1]);
    data.append("dob", moment(value).format("YYYY-MM-DD"));
    data.append("attachment", file);

    try {
      const res = await API({
        method: "POST",
        url: "career/",
        requestConfig: data,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <AboutHeader>Career</AboutHeader>
      <Container sx={{ minHeight: "40vh" }}>
        <Box
          sx={{ p: 5 }}
          id="career-form"
          component={"form"}
          onSubmit={handleSubmit}
        >
          <Stack spacing={3}>
            <Stack direction={"row"} spacing={2}>
              <TextField
                size="small"
                required
                fullWidth
                label="Full Name"
                name="full-name"
                id="full-name"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  views={["year", "month", "day"]}
                  minDate={dayjs("1950-01-01")}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{ alignItems: "center" }}>
              <FormControl fullWidth>
                <FormLabel id="gender-radio-buttons-group-label">
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="gender-radio-buttons-group-label"
                  defaultValue="male"
                  name="gender"
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                size="small"
                fullWidth
                required
                label="Phone"
                name="phone"
                id="phone-number"
              />
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                size="small"
                fullWidth
                required
                label="Email"
                name="email"
                id="email"
              />
              <TextField
                size="small"
                fullWidth
                required
                label="Qualification"
                name="qualification"
                id="qualification"
              />
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                size="small"
                fullWidth
                required
                label="City"
                name="city"
                id="city"
              />
              <TextField
                size="small"
                fullWidth
                required
                label="Area"
                name="address"
                id="area"
              />
            </Stack>
            <Stack direction={"row"} spacing={2} sx={{ alignItems: "center" }}>
              <TextField
                size="small"
                fullWidth
                label="Position Applied For"
                name="position"
                id="position"
              />
              <FormControl fullWidth>
                <FormLabel id="pharmacy-experience-buttons-group-label">
                  Pharmacy Experience
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="pharmacy-experience-buttons-group-label"
                  defaultValue="No"
                  name="experience"
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Stack>
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            />
            <Box
              component={"div"}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </Stack>
        </Box>
      </Container>
      <Modal
        open={loading}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Modal>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackBar(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Your submission has been received.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Career;
