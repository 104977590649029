import React, { useEffect, useState, useContext } from "react";
import { Container, Grid, Typography } from "@mui/material";

import axios from "axios";
import baseURL from "../Assets/common/baseURL";
import TrackOrderCard from "../Components/TrackOrder/TrackOrderCard";

import LoaderImage from "../Assets/images/loader.gif";
import API from "../utils/API";
import AuthGlobal from "../Context/store/AuthGlobal";

function TrackOrder() {

    const context = useContext(AuthGlobal);

  const [orderData, setOrderData] = useState([]);
  const [orderCount, setOrderCount] = useState("");
  const [isLoading, SetLoading] = useState(false);

  useEffect(() => {
    loadOrders();
  }, []);

  const loadOrders = async () => {
    const res = await API({
      method: "GET",
      url: "invoice/?customer=" + context.stateUser.user.user,
      requireAuth: true,
    });
    setOrderData(res);
    setOrderCount(res.length);
    SetLoading(true);
  };

  return (
    <div>
      {isLoading ? (
        <Container maxWidth={"xl"} sx={{ p: 2, minHeight: "45vh" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h3">Your Orders</Typography>
          </div>
          <Typography variant={"h6"}>
            <b>Number of Orders: {orderCount}</b>
          </Typography>
          <Grid container>
            {[...orderData].reverse().map((order) => {
              return <TrackOrderCard data={order} />;
            })}
          </Grid>
        </Container>
      ) : (
        <div
          style={{
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoaderImage} height={500} />
        </div>
      )}
    </div>
  );
}

export default TrackOrder;
