import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import {
  Box,
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";

import UploadPrescriptionInfo from "../Components/UploadPrescriptionInfo";

import PrescriptionImage from "../Assets/images/PrescriptionSample.png";
import baseURL from "../Assets/common/baseURL";
import API from "../utils/API";
import formDataAPI from "../api/formDataAPI";
import { LoadingButton } from "@mui/lab";

function UploadPrescription() {
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState();

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [remarks, setRemarks] = useState();

  const [fields, setFields] = useState({
    name: "",
    phone: "",
    address: "",
    remarks: "",
  });

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const handleTextChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const fileTypes = ["JPG", "PDF"];

  const handleChange = (image) => {
    setImage(image);
  };

  const userData = {
    name,
    phone,
    address,
    remarks,
  };

  const date = new Date();
  date.getDate();

  console.log(image);

  const placeOrder = async () => {
    if (image === null) {
      return alert("Please upload a prescription image.");
    }
    if (fields.name === "" || fields.phone === "" || fields.address === "") {
      return alert("All fields marked with * are required and must be filled.");
    }
    if (fields.phone.length < 9 || fields.phone.length > 11) {
      return alert("Phone number should be between 9 to 11 digits.");
    }

    const data = new FormData();
    data.append("image", image);
    data.append("name", fields.name);
    data.append("phone_number", fields.phone);
    data.append("address", fields.address);
    data.append("remarks", fields.remarks);
    // data.append('created', new Date())

    setLoading(true);

    const res = await API({
      method: "POST",
      url: "fastorder/",
      requestConfig: data,
      instance: formDataAPI,
    });
    setOpen(true);
    setLoading(false);
  };

  //   const placeOrder = () => {
  //     if (image !== null) {
  //       const filename = name + "-" + date + ".jpg";
  //       /*const updatedImage = new File(image, filename, {
  //                 type: "image/jpeg",
  //                 lastModified: Date.now()
  //             })*/
  //       form.append("image", image);
  //       fetch(`${baseURL}fastOrder`, {
  //         method: "POST",
  //         body: JSON.stringify(userData),
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //       })
  //         .then((res) => res.json())
  //         .then((data) => {
  //           if (data.success === true) {
  //             form.append("id", data.data.id);
  //             fetch(`${baseURL}fastOrderImage`, {
  //               method: "POST",
  //               body: form,
  //             });
  //           }
  //         })
  //         .then(() => {
  //           setOpen(true);
  //         });
  //     }
  //   };

  return (
    <Container component="main" sx={{ mt: 1, mb: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={{ mt: 5, mb: 5 }}>
          <div style={{ marginTop: 20 }}>
            <UploadPrescriptionInfo />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", margin: 10 }}
          >
            {image && (
              <img
                src={preview}
                height="20%"
                width="20%"
                style={{ borderRadius: 15 }}
              />
            )}
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                margin="normal"
                fullWidth
                label="Name"
                id="full-name"
                className="full-name"
                name="name"
                value={fields.name}
                onChange={(e) => handleTextChange(e)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                margin="normal"
                fullWidth
                label="Phone No."
                type={"tel"}
                id="phone"
                className="phone"
                name="phone"
                value={fields.phone}
                onChange={(e) => handleTextChange(e)}
                required
              />
            </Grid>
          </Grid>
          <TextField
            size="small"
            margin="normal"
            fullWidth
            label="Address"
            id="address"
            className="address"
            name="address"
            value={fields.address}
            onChange={(e) => handleTextChange(e)}
            required
          />
          <TextField
            margin="normal"
            fullWidth
            label="Note"
            id="note"
            className="note"
            name="remarks"
            value={fields.remarks}
            onChange={(e) => handleTextChange(e)}
          />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton variant="contained" onClick={placeOrder} loading={loading} >
              Place Order
            </LoadingButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{}}>
            <div style={{}}>
              <Typography margin={2} marginLeft={5} variant={"h5"}>
                <b>How Do You Upload Your Prescription</b>
              </Typography>
            </div>
            <Typography>
              Don't remove any of the prescription image's pixels. Avoid having
              your prescription in an unclear or blurry image.
            </Typography>
          </div>
          <div>
            <img src={PrescriptionImage} height={"90%"} width={"90%"} />
          </div>
        </Grid>
      </Grid>
      <Dialog open={open}>
        <DialogContent>
          <Alert>
            We have received your Prescription and Our team will contact you
            soon.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => navigate("/")}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default UploadPrescription;
