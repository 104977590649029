import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

//Material UI
import { Box, Container, Stack, TextField, Button } from '@mui/material'

//Fetch
import baseURL from '../../Assets/common/baseURL';

function AccountDetails(props) {

  const navigate = useNavigate()

  const [fullname] = useState(props.userProfile ? props.userProfile.name : '');
  const [email] = useState(props.userProfile ? props.userProfile.email : '');
  const [phone] = useState(props.userProfile ? props.userProfile.phone : '');
  const [address, setAddress] = useState(props.userProfile ? props.userProfile.address : '')

  const name = props.userProfile ? props.userProfile.name : ''

  const UpdateAddress = () => {

    const updateAddress = {
      id: props.userProfile.id,
      address
    };


    fetch(`${baseURL}AddAddress`, {
      method: 'POST',
      body: JSON.stringify(updateAddress),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          navigate(0)
        }
      })
  };

  return (
    <Container>
      <Box sx={{ p: 2 }} >
        <Stack spacing={2} >
          <TextField label='Name' value={fullname} disabled />
          <TextField label='Email' value={email} disabled />
          <TextField label='Phone' value={phone} disabled />
          <TextField label='Address' value={address} onChange={(e) => setAddress(e.target.value)} />
          <Box display={'flex'} justifyContent={'flex-end'} >
            <Button variant={'contained'} onClick={UpdateAddress} sx={{ m: 2 }} >Save</Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  )
}

export default AccountDetails