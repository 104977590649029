import { Navigate, useLocation } from "react-router-dom";

const Protected = ({ isLoggedIn, children, setLocation }) => {
  const location = useLocation();

  setLocation(location);

  if (isLoggedIn === false) {
    return <Navigate to="/account/signin" />;
  }
  return children;
};

export default Protected;
