import LoggedIn from "../Components/LoggedIn";
import Protected from "../Components/Protected";

//Pages
import Career from "./Career";
import Profile from "./Profile";
import LabTests from "./LabTests";
import Branches from "./Branches";
import HomePage from "./HomePage";
import Checkout from "./Checkout";
import CartPage from "./CartPage";
import Feedback from "./Feedback";
import Signin from "./User/Signin";
import Signup from "./User/Signup";
import Labs from "./LabTests/Labs";
import Franchise from "./Franchise";
import App from "../Components/App";
import Tests from "./LabTests/Tests";
import Account from "./User/Account";
import ComingSoon from "./ComingSoon";
import OurTeam from "./About/OurTeam";
import TrackOrder from "./TrackOrder";
import Doctors from "./Doctors/Doctors";
import PageNotFound from "./PageNotFound";
import TeamMember from "./About/TeamMember";
import OurHistory from "./About/OurHistory";
import PhoneNumber from "./User/PhoneNumber";
import DetailsPage from "./Details/DetailsPage";
import PrivacyPolicy from "./About/PrivacyPolicy";
import ScrollToTop from "../Components/ScrollToTop";
import DoctorsNavigation from "./DoctorsNavigation";
import SecurityPolicy from "./About/SecurityPolicy";
import ShowProducts from "./Categories/ShowProducts";
import UploadPrescription from "./UploadPrescription";
import SubCatProducts from "./Categories/SubCatProducts";
import ShowSearchedProducts from "./ShowSearchedProducts";
import ShippingandReturns from "./About/ShippingandReturns";
import DoctorAppointment from "./Doctors/DoctorAppointment";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ForgotPasswordOTP from "./ForgotPassword/ForgotPasswordOTP";

import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ConsultPharmacist from "./Doctors/ConsultPharmacist";
import Email from "./User/Email";

function Navigation(props) {
  const [location, setLocation] = useState();

  return (
    <div>
      <ScrollToTop>
        <Routes>
          <Route
            path="/"
            element={
              <App
                authStatus={props.authStatus}
                userProfile={props.userProfile}
              />
            }
          >
            <Route path="/" element={<HomePage />} />
            <Route path="/product" element={<DetailsPage />} />
            <Route
              path="/profile"
              element={
                <Protected
                  isLoggedIn={props.authStatus}
                  setLocation={setLocation}
                >
                  <Profile userProfile={props.userProfile} />
                </Protected>
              }
            />
            <Route path="/cart" element={<CartPage />} />
            <Route
              path="/checkout"
              element={
                <Protected
                  isLoggedIn={props.authStatus}
                  setLocation={setLocation}
                >
                  <Checkout userProfile={props.userProfile} />
                </Protected>
              }
            />
            <Route path="/fastorder" element={<UploadPrescription />} />
            <Route path="/consult-pharmacist" element={<ConsultPharmacist />} />
            <Route path="/branches" element={<Branches />} />
            <Route path="/showproducts" element={<ShowProducts />} />
            <Route path="/subcategory">
              <Route path=":subCatID" element={<SubCatProducts />} />
            </Route>
            <Route path="/search">
              <Route path=":searchQuery" element={<ShowSearchedProducts />} />
            </Route>
            <Route
              path="/Feedback"
              element={
                <Protected
                  isLoggedIn={props.authStatus}
                  setLocation={setLocation}
                >
                  <Feedback userProfile={props.userProfile} />
                </Protected>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/our-history" element={<OurHistory />} />
            <Route path="/security-policy" element={<SecurityPolicy />} />
            <Route
              path="/shipping-and-returns"
              element={<ShippingandReturns />}
            />
            <Route
              path="/track-order"
              element={
                <Protected
                  isLoggedIn={props.authStatus}
                  setLocation={setLocation}
                >
                  <TrackOrder userProfile={props.userProfile} />
                </Protected>
              }
            />
            <Route path="/labs" element={<LabTests />}>
              <Route path="/labs" element={<Labs />} />
              <Route path="/labs/tests">
                <Route path=":LabID" element={<Tests />} />
              </Route>
            </Route>
            <Route
              path="/doctors"
              element={
                <Protected
                  isLoggedIn={props.authStatus}
                  setLocation={setLocation}
                >
                  <DoctorsNavigation />
                </Protected>
              }
            >
              <Route path="/doctors" element={<Doctors />} />
              <Route
                path="/doctors/doctor-appointment"
                element={<DoctorAppointment userProfile={props.userProfile} />}
              />
            </Route>
            <Route path="/careers" element={<Career />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/team-member" element={<TeamMember />} />
            <Route path="/franchise" element={<Franchise />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/account"
            element={
              <LoggedIn isLoggedIn={props.authStatus} location={location}>
                <Account />
              </LoggedIn>
            }
          >
            <Route path={"/account/phone"} element={<PhoneNumber />} />
            <Route path={"/account/email"} element={<Email />} />
            <Route path={"/account/signin"} element={<Signin />} />
            <Route path={"/account/signup"} element={<Signup />} />
            <Route path={"/account/forgotpassword"}>
              <Route
                path="/account/forgotpassword/otp"
                element={<ForgotPasswordOTP />}
              />
              <Route
                path="/account/forgotpassword"
                element={<ForgotPassword />}
              />
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default Navigation;
