import React from "react";
import EmptyLogo from "../../Assets/images/Emptylogo.png";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../Redux/Actions/cartAction";
import {
  Box,
  Card,
  Button,
  useTheme,
  Typography,
  CardContent,
  useMediaQuery,
  CardActionArea,
} from "@mui/material";

function ProductCard({ product, responsive, title, addItemToCart }) {
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isSingleView = useMediaQuery(theme.breakpoints.down("442"));

  const image = product.image;

  const payloadData = {
    id: product.id,
    name: product.name,
    qty: 1,
    image: product.image,
    prescription_required: product.prescription_required,
    price: product.price_unit,
    isPack: false,
  };

  return (
    <Link
      to={"../../product"}
      state={{ product: product }}
      style={{ textDecoration: "none", color: "inherit" }}
      onClick={() => document.documentElement.scrollTo(0, 0)}
    >
      <Card
        sx={{
          margin: isMatch ? 0.5 : 1,
          width: isMatch ? (responsive && isSingleView ? "100%" : 180) : 275,
        }}
      >
        <CardActionArea
          sx={responsive && isSingleView ? { display: "flex" } : {}}
        >
          <img
            src={image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = EmptyLogo;
            }}
            alt={title}
            style={{
              backgroundImage: "url(" + EmptyLogo + ")",
              height: isMatch ? (responsive && isSingleView ? 70 : 150) : 275,
              width: responsive ? (isSingleView ? 70 : "100%") : "100%",
            }}
          />

          <CardContent
            style={
              responsive && isSingleView
                ? {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }
                : {}
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems:
                  responsive && isSingleView ? "flex-start" : "center",
              }}
            >
              <Typography style={{ textAlign: "center" }}>
                <b>
                  {product.name.length > (isMatch ? 20 : 25)
                    ? product.name.substring(0, isMatch ? 20 - 3 : 25 - 3) +
                      "..."
                    : product.name}
                </b>
              </Typography>

              <Box
                sx={{
                  m: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography color={"secondary"}>
                  Rs. <b>{product.price_unit}</b>
                </Typography>
              </Box>
            </div>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                small
                variant="contained"
                style={{
                  marginTop: responsive && isSingleView ? 0 : 10,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  addItemToCart(payloadData);
                }}
              >
                {responsive && isSingleView ? "ADD" : "ADD TO CART"}
              </Button>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToCart: (data) => dispatch(actions.addToCart(data)),
  };
};

export default connect(null, mapDispatchToProps)(ProductCard);
