import Search from "./Search";
import logo from "../../Assets/images/logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  List,
  Button,
  Drawer,
  ListItem,
  Collapse,
  useTheme,
  ListItemButton,
} from "@mui/material";

const MenuDrawer = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const drawerWidth = "100%";
  const categories = props.categories;
  const subCategories = props.subCategories;

  const [openCategories, setOpenCategories] = useState({});

  const styles = {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: drawerWidth,
      },
    },
    container: {
      p: 2,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    closeIconContainer: {
      display: "flex",
      padding: "10px",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
    },
  };

  const handleHomeClick = () => {
    navigate("/");
    props.setOpen();
    setOpenCategories({});
  };

  const handleCreateFastOrderClick = () => {
    navigate("/fastorder");
    props.setOpen();
    setOpenCategories({});
  };

  const handleTrackOrderClick = () => {
    navigate("/track-order");
    props.setOpen();
    setOpenCategories({});
  };

  const handleFeedbackClick = () => {
    navigate("/Feedback");
    props.setOpen();
    setOpenCategories({});
  };

  const handleOurBranchesClick = () => {
    navigate("/branches");
    props.setOpen();
    setOpenCategories({});
  };

  const handleProfileClick = () => {
    navigate("/profile");
    props.setOpen();
    setOpenCategories({});
  };

  const handleLoginClick = () => {
    navigate("/account");
    props.setOpen();
    setOpenCategories({});
  };

  const handleCategoryItemClick = (id) => {
    navigate(`/subcategory/${id}`);
    props.setOpen();
    setOpenCategories({});
  };

  const handleCategoryToggle = (categoryId) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  return (
    <>
      <Drawer
        open={props.open}
        sx={styles.drawer}
        variant="temporary"
        onClose={() => props.setOpen(false)}
      >
        <Box sx={styles.container}>
          <div style={{ cursor: "pointer" }}>
            <img
              src={logo}
              alt={"NMP Pharmacy"}
              style={{ height: "90%", width: "90%" }}
              onClick={() => {
                navigate("/");
                document.documentElement.scrollTo(0, 0);
              }}
            />
          </div>

          <div style={styles.closeIconContainer}>
            <CloseIcon
              style={{ color: "#ffffff" }}
              onClick={() => props.setOpen(false)}
            />
          </div>
        </Box>

        <Box sx={{ p: 2, marginRight: 2 }}>
          <Search
            setOpen={() => {
              props.setOpen();
            }}
          />
        </Box>

        <List>
          <ListItemButton onClick={handleHomeClick}>Home</ListItemButton>
          <ListItemButton onClick={handleCreateFastOrderClick}>
            Create Fast Order
          </ListItemButton>
          <ListItemButton onClick={handleTrackOrderClick}>
            Track Order
          </ListItemButton>
          <ListItemButton onClick={handleFeedbackClick}>
            Feedback
          </ListItemButton>
          <ListItemButton onClick={handleOurBranchesClick}>
            Our Branches
          </ListItemButton>

          {props.authStatus ? (
            <ListItemButton onClick={handleProfileClick}>
              Profile
            </ListItemButton>
          ) : (
            <ListItemButton onClick={handleLoginClick}>Login</ListItemButton>
          )}

          <hr />

          {categories.map((category, index) => {
            const isOpen = openCategories[category.id] || false;

            return (
              <div key={index} style={{ marginLeft: 8 }}>
                <Button onClick={() => handleCategoryToggle(category.id)}>
                  {category.name}
                  {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>

                <Collapse in={isOpen}>
                  <List>
                    {subCategories.map((subcategory, subIndex) => {
                      if (category.id === subcategory.cat_id) {
                        return (
                          <ListItem
                            key={subIndex}
                            style={{ fontSize: "small" }}
                            onClick={() =>
                              handleCategoryItemClick(subcategory.id)
                            }
                          >
                            {subcategory.name}
                          </ListItem>
                        );
                      }
                      return null;
                    })}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
