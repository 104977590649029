import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  CssBaseline,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

//Fetch
import API from "../../utils/API";
import { ChevronLeft } from "@mui/icons-material";

function ForgotPassword() {
  const navigate = useNavigate();

  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [otp, setOtp] = useState();
  const [otpInput, setOtpInput] = useState();
  const [timer, setTimer] = useState(60);

  const [step, setStep] = useState(1);

  const handleSendOTP = async () => {
    setTimer(60);
    if (!phone || phone.length !== 11) {
      return alert("Phone number must be 11 digits.");
    }
    try {
      const res = await API({
        method: "POST",
        url: "forget/",
        requestConfig: {
          phone: phone,
        },
      });
      setStep(2);
      setOtp(res.otp);
      let time = 60;
      const interval = setInterval(() => {
        time--;
        setTimer(time);
        if (time === 0) {
          clearInterval(interval);
        }
      }, 1000);
    } catch (error) {
      if (error.message === "Request failed with status code 400") {
        alert(
          "Error",
          "Phone number does not exist, please register your account."
        );
      } else {
        alert("Error", "An Error Occured");
      }
    }
  };

  const handleConfirmOTP = async () => {
    if (otpInput === otp.toString()) {
      setStep(3);
    } else {
      alert("OTP does not match");
    }
  };

  const handleChangePassword = async () => {
    if (password !== confirmpassword) {
      setPasswordMismatch(true);
      return;
    }
    try {
      const res = await API({
        method: "POST",
        url: "setpassword/",
        requestConfig: {
          username: phone,
          new_password: password,
          confirm_password: confirmpassword,
        },
      });
      alert("Password Changed Successfully");
      navigate("/account/signin");
    } catch (error) {
      console.log(error);
      alert("An Error Occured");
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: "flex", height: "100vh", alignItems: "center" }}
    >
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: 4,
          borderRadius: 5,
          textAlign: "left",
        }}
      >
        {step === 1 && (
          <>
            <Typography component="h4" variant="h6" fontWeight={"bold"}>
              Verify Phone Number
            </Typography>
            <Typography>
              Please enter your phone number to receive a 6-digit OTP.
            </Typography>
            <Stack spacing={2} sx={{ mt: 3 }}>
              <TextField
                label="Phone"
                fullWidth
                type={"phone"}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <Button variant="contained" onClick={handleSendOTP}>
                Confirm
              </Button>
            </Stack>
          </>
        )}
        {step === 2 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton color="primary" onClick={() => setStep(1)}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Stack
              spacing={2}
              sx={{ mt: 2, display: "flex", flexDirection: "column" }}
            >
              <Typography component="h4" variant="h5" fontWeight={"bold"}>
                Enter OTP
              </Typography>
              <Typography>
                Please enter the 6-digit PIN Code sent to {phone}.
              </Typography>
            </Stack>
            <Stack spacing={2} sx={{ mt: 3 }}>
              <TextField
                label="OTP"
                fullWidth
                value={otpInput}
                onChange={(e) => setOtpInput(e.target.value)}
              />
              <Typography>Resend OTP in {timer} seconds</Typography>
              {timer === 0 && (
                <Button variant="contained" onClick={handleSendOTP}>
                  Resend
                </Button>
              )}
              <Button variant="contained" onClick={handleConfirmOTP}>
                Confirm
              </Button>
            </Stack>
          </>
        )}
        {step === 3 && (
          <>
            <Stack spacing={2} >
              <Typography component="h3" variant="h5" fontWeight={"bold"}>
                Create Password
              </Typography>
              <Typography>
                Create a new password for your account. You will use this password to login to your account.
              </Typography>
              <Stack spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label="New Password"
                  fullWidth
                  type={"password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  label="Confirm Password"
                  fullWidth
                  type={"password"}
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Collapse in={passwordMismatch}>
                  <Alert
                    onClose={() => setPasswordMismatch(false)}
                    severity="error"
                  >
                    Password Doesn't Match
                  </Alert>
                </Collapse>
                <Button variant="contained" onClick={handleChangePassword}>
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </Container>
  );
}

export default ForgotPassword;
