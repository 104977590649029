import axios from "axios";
import Banner from "../Components/Banner";
import LoaderImage from "../Assets/images/loader.gif";
import ProductContainer from "../Components/Products/ProductContainer";
import FeedbackContainer from "../Components/Feedback/FeedbackContainer";

import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Stack,
  useTheme,
  Typography,
  useMediaQuery,
  CardActionArea,
  Card,
} from "@mui/material";
import API from "../utils/API";
import BrandContainer from "../Components/Brands/BrandContainer";

function HomePage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [banners, setBanners] = useState([]);
  const [type, setType] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isLoading, SetLoading] = useState(false);

  useEffect(() => {
    loadProducts();
    loadFeedbacks();
    loadBrands();
  }, []);

  const loadProducts = async () => {
    const res = await API({
      method: "GET",
      url: "slider",
    });
    setBanners(res);
    const typeRes = await API({
      method: "GET",
      url: "type",
    });
    typeRes.map(async (item, index) => {
      const productRes = await API({
        method: "GET",
        url: `product?type=${item.id}`,
      });
      setType((prev) => [
        ...prev,
        { id: item.id, name: item.name, data: productRes },
      ]);
    });

    SetLoading(true);
  };

  const loadFeedbacks = async () => {
    const res = await API({
      method: "GET",
      url: "feedback",
    });
    setFeedbacks(res);
  };

  const loadBrands = async () => {
    const res = await API({
      method: "GET",
      url: "brand",
    });
    setBrands(res.filter((item) => item.web === true));
  };

  return (
    <div>
      {isLoading ? (
        <div>
          <Banner banners={banners} />
          <Box sx={{ m: 2 }}>
            {type
              ?.sort((a, b) => a.id - b.id)
              ?.map((item, index) => (
                <ProductContainer
                  key={index}
                  heading={item.name}
                  products={item.data}
                />
              ))}
            {brands.length > 0 && (
              <BrandContainer heading={"Brands"} brands={brands} />
            )}
            {feedbacks.filter((item) => item.active === true).length > 0 && (
              <FeedbackContainer
                heading={"Reviews"}
                feedbacks={feedbacks.filter((item) => item.active === true)}
              />
            )}
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            minHeight: "30vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={LoaderImage} height={500} alt="Alt value" />
        </div>
      )}
    </div>
  );
}

export default HomePage;
