import { Card, CardActionArea } from "@mui/material";
import React from "react";

const BrandCard = ({ data, primaryColor }) => {
  return (
    <Card
      sx={{
        width: 100,
        height: 100,
        margin: 2,
        borderRadius: 0,
        boxShadow: "none",
        backgroundColor: primaryColor,
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
        },
      }}
    >
      <CardActionArea>
        <img
          src={data.image}
          alt={data.name}
          style={{ width: "100%", height: "auto" }}
        />
      </CardActionArea>
    </Card>
  );
};

export default BrandCard;
