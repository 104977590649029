import React, { useEffect, useState } from 'react'
import { Container, Box, Button, Link, Menu, MenuItem, Typography, Stack, Breadcrumbs } from '@mui/material'
import SortIcon from '@mui/icons-material/Sort';

import LoaderImage from '../../Assets/images/loader.gif'

import FlatList from 'flatlist-react';

import axios from 'axios'
import baseURL from '../../Assets/common/baseURL'
import ProductCard from '../../Components/Products/ProductCard';


function ShowProducts() {

  const [products, setProducts] = useState([])

  const [tabValue, setTabValue] = useState('A')

  useEffect(() => {
    axios
      .get(`${baseURL}products`)
      .then(res => setProducts(res.data.data.products))
  }, [])

  const renderProduct = (item) => {
    return (
      <ProductCard product={item} responsive={true} />
    )
  }

  const handleChange = (item) => {
    setTabValue(item);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const alphabets = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ]


  return (
    <Container maxWidth={'xl'} sx={{ mt: 2 }}>
      <Breadcrumbs>
        <Link underline="hover" color="inherit" href="/" >
          Home
        </Link>
        <Link underline="hover" color="inherit" >
          A to Z
        </Link>
      </Breadcrumbs>
      <Stack spacing={2} >
        <Box>
          <Typography fontSize={22} fontWeight={'bold'} >Medicines Starting with {tabValue}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} sx={{ p: 1, pl: 2, pr: 2, backgroundColor: '#2c3c93', borderRadius: 1 }} >
          {alphabets.map((item) => {
            return (
              <Link onClick={() => handleChange(item)} sx={{ cursor: 'pointer' }} ><Typography sx={{ color: '#fff', fontWeight: item === tabValue ? '800' : 'normal', textDecorationLine: item === tabValue ? 'underline' : 'none' }} >{item}</Typography></Link>
            )
          })}
        </Box>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          id="sort-menu-button"
          aria-controls={open ? 'sort-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography fontSize={16} fontWeight={'bold'} sx={{ mr: 1, }} >Sort By: </Typography><SortIcon fontSize='large' />
        </Button>
        <Menu
          id="sort-menu"
          aria-labelledby="sort-menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            setAnchorEl(null)
            setProducts([...products].sort((a, b) => a.price - b.price))
          }} >Price: Low to High</MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setProducts([...products].sort((a, b) => b.price - a.price))
            }}
          >Price: High to Low</MenuItem>
        </Menu>
      </Box>
      <Box>
        <FlatList
          list={products}
          renderItem={renderProduct}
          renderWhenEmpty={() => (
            <div style={{ minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <img src={LoaderImage} height={500} />
            </div>
          )}
          renderOnScroll
          display={{
            grid: true,
            minColumnWidth: "100px",
            gridGap: "80px"
          }}
          filterBy={item => item.title.toUpperCase().startsWith(tabValue)}
        />
      </Box>
    </Container>
  )
}

export default ShowProducts