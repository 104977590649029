import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  CssBaseline,
} from "@mui/material";

import baseURL from "../../Assets/common/baseURL";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import API from "../../utils/API";
import OtpInput from "react-otp-input";
import { LoadingButton } from "@mui/lab";

function Email() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [phone, setPhone] = useState("");
  const [timer, setTimer] = useState(60);
  const [input, setInput] = useState("");

  const sendOTP = async () => {
    if (phone.includes("@") === false) {
        return alert("Please enter a valid email.");
    }
    setLoading(true);
    try {
      const res = await API({
        method: "POST",
        url: "signup/",
        requestConfig: {
          email: phone,
        },
      });
      setOtpSent(true);
      let time = 60;
      const interval = setInterval(() => {
        time--;
        setTimer(time);
        if (time === 0) {
          clearInterval(interval);
        }
      }, 1000);
      setOtp(res.otp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: "flex", height: "100vh", alignItems: "center" }}
    >
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: 4,
          borderRadius: 5,

          textAlign: "left",
        }}
      >
        <KeyboardBackspaceIcon
          onClick={() => navigate("/")}
          color="primary"
          sx={{ mb: 2 }}
        />
        <Typography component="h1" variant="h5">
          <b>Enter Email</b>
        </Typography>
        <Typography variant="p">
          Enter your email to receive OTP
        </Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            id="email"
            type="email"
            label="Email"
            placeholder="abc@xyz.com"
            margin="normal"
            required
            fullWidth
            onChange={(input) => setPhone(input.target.value)}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            margin="normal"
            onClick={sendOTP}
            disabled={otpSent}
            fullWidth
            sx={{ mt: 2 }}
          >
            Send OTP
          </LoadingButton>
        </Box>
        {otpSent && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Typography component="h1" variant="h5">
              Enter OTP
            </Typography>
            <Box sx={{ mt: 2, mb: 2 }}>
              <OtpInput
                value={input}
                onChange={(otp) => {
                  setInput(otp);
                }}
                numInputs={6}
                inputStyle={{
                  height: 40,
                  width: 30,
                  borderRadius: 5,
                  margin: 5,
                  fontSize: 20,
                  color: "blue",
                }}
                separator={<span>-</span>}
              />
            </Box>
            <Button
              variant="contained"
              type="submit"
              margin="normal"
              onClick={() => {
                if (parseInt(input) === otp) {
                  navigate("/account/signup", { state: {
                    email: phone,
                  } });
                }
              }}
              fullWidth
            >
              Verify
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Email;
