import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  CssBaseline,
  Typography,
  Box,
  Modal,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Alert,
} from "@mui/material";

import { loginUser } from "../../Context/actions/Auth.actions";
import AuthGlobal from "../../Context/store/AuthGlobal";
import baseURL from "../../Assets/common/baseURL";
import API from "../../utils/API";
import { LoadingButton } from "@mui/lab";

function Signup() {
  const context = useContext(AuthGlobal);

  const data = useLocation().state;

  const [loading, setLoading] = useState(false);
  const [fullname, setName] = useState("");
  const [email, setEmail] = useState(data?.email ? data.email : "");
  const [phone, setPhone] = useState(data?.phone ? data.phone : "");
  const [password, setPassword] = useState("");

  const [fullnameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [consent, setConsent] = useState(false);

  const [emailExists, setEmailExists] = useState(false);

  const user = {
    fullname,
    email,
    phone,
    password,
  };

  const onSignUpPressed = async (e) => {
    e.preventDefault();
    if (!fullname) {
      return alert("Please enter your full name.");
    }
    if (!password) {
      return alert("Please enter a password.");
    }
    if (!consent) {
      return alert("Please agree to the terms and conditions.");
    }
    setLoading(true);
    try {
      if (fullname && password) {
        const customerRes = await API({
          method: "GET",
          url: `customer/?search=${data.phone ? data.phone : data.email}`,
        });
        const updateRes = await API({
          method: "PUT",
          url: `customer/${customerRes[0].id}/`,
          requestConfig: {
            name: fullname,
            email,
            phone,
          },
        });
        const updatePasswordRes = await API({
          method: "POST",
          url: `setpassword/`,
          requestConfig: {
            username: data.phone ? data.phone : data.email,
            new_password: password,
            confirm_password: password,
          },
        });
        const res = await API({
          method: "POST",
          url: "api-token-auth/",
          requestConfig: {
            username: data.phone ? data.phone : data.email,
            password: password,
          },
        });
        loginUser(res.token, context.dispatch);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: "flex", height: "100vh", alignItems: "center" }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: 5,
          borderRadius: 10,
        }}
      >
        <Typography component="h1" variant="h4">
          Register
        </Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            label="Full Name"
            name="name"
            type="text"
            id="name"
            autoComplete="current-password"
            margin="normal"
            required
            fullWidth
            value={fullname}
            error={fullnameError}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            id="email"
            autoComplete="current-password"
            margin="normal"
            fullWidth
            value={email}
            disabled={data?.email ? true : false}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            type="tel"
            label="Phone"
            value={phone}
            disabled={data?.phone ? true : false}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            margin="normal"
            required
            fullWidth
            value={password}
            error={passwordError}
            onChange={(event) => setPassword(event.target.value)}
          />
          {emailExists ? (
            <Alert severity="error">Email Already Exists</Alert>
          ) : null}
          <div style={{ display: "flex" }}>
            <Checkbox
              color="primary"
              value={consent}
              onChange={(e) => setConsent(e.target.checked)}
            />
            <Typography>
              I have read and agree to{" "}
              <a href="https://nmp.com.pk/privacy-policy" target="_blank">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://nmp.com.pk/shipping-and-returns" target="_blank">
                Shipping {`&`} Returns
              </a>
            </Typography>
          </div>
          <LoadingButton
            loading={loading}
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            onClick={onSignUpPressed}
          >
            Sign Up
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}

export default Signup;
